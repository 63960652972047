import dayjs from 'dayjs'
import IConnectQuotation from '~/Interfaces/connectQuotaton/IConnectQuotation'
import DayService from '~/components/Services/DayService'
import IConnectQuotationDiffer from '~/Interfaces/connectQuotaton/IConnectQuotationDiffer'
import IFixedReward from '~/Interfaces/connectQuotaton/IFixedReward'

interface IGroupedFixedReward {
  fixedRewardPaymentDate: string
  rowspan: number
  items: IFixedReward[]
}

export default class ConnectQuotation {
  value: IConnectQuotation
  dayService: DayService

  connectCompanyTelTemporary: string | undefined = ''

  static readonly VERSION2 = 2

  static readonly CONSUMPTION_TAX_RATE = 0.1

  constructor(connectQuotation: IConnectQuotation) {
    this.value = connectQuotation
    // @ts-ignore
    this.dayService = new DayService(dayjs)

    this.connectCompanyTelTemporary = connectQuotation.connectCompanyTel
  }

  public static fixedRewards(fixedRewards: IFixedReward[]): IFixedReward[] {
    const frs = [...fixedRewards]
    const fixedRewardsWithOrder = [] as IFixedReward[]

    const sortedFixedRewards = frs.sort((a, b) => {
      const aOrder = a.fixedRewardOrder ?? 0
      const bOrder = b.fixedRewardOrder ?? 0

      if (aOrder > bOrder) {
        return 1
      }
      if (aOrder < bOrder) {
        return -1
      }
      return 0
    })

    sortedFixedRewards.forEach((fixedReward, order) => {
      fixedRewardsWithOrder.push({
        ...fixedReward,
        hash: fixedReward.hash ?? ConnectQuotation.generateHash(),
        fixedRewardOrder: order,
        fixedRewardPrice: fixedReward.fixedRewardPrice
          ? fixedReward.fixedRewardPrice
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          : fixedReward.fixedRewardPrice,
        fixedRewardCount: fixedReward.fixedRewardCount
          ? fixedReward.fixedRewardCount
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          : fixedReward.fixedRewardCount,
        fixedRewardTotal: fixedReward.fixedRewardTotal
          ? fixedReward.fixedRewardTotal
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          : fixedReward.fixedRewardTotal,
      })
    })

    return fixedRewardsWithOrder
  }

  public static generateHash(): string {
    return Math.random().toString(36).slice(-8)
  }

  get fixedRewards(): IFixedReward[] {
    if (!this.value.fixedRewards) {
      return []
    }

    return ConnectQuotation.fixedRewards(this.value.fixedRewards)
  }

  isFromConnectCompany(): boolean {
    return this.value.from === 'connect_company'
  }

  isFromConnectClient(): boolean {
    return this.value.from === 'connect_client'
  }

  canEditByConnectCompany(): boolean {
    if (this.value.from === undefined) {
      return true
    }
    if (this.value.isClientAgree === undefined) {
      return true
    }
    if (this.value.isClientAgree) {
      return false
    }

    return this.value.from === 'connect_client'
  }

  setCanEditByConnectCompany(): void {
    this.value.isClientAgree = false
  }

  setFromConnectCompany(): void {
    this.value.from = 'connect_company'
  }

  getContractStartDay(): string {
    if (!this.value.contractStartDay) {
      return ''
    }

    return this.dayService.getYYYYMMDD(this.value.contractStartDay)
  }

  getContractEndDay(): string {
    if (!this.value.contractEndDay) {
      return ''
    }

    return this.dayService.getYYYYMMDD(this.value.contractEndDay)
  }

  getContractPeriod(): string {
    if (!this.value.contractPeriod) {
      return ''
    }

    return this.value.contractPeriod
  }

  getCreatedAt(): string {
    if (!this.value.createdAt) {
      return ''
    }

    return this.dayService.getYYYYMMDD(this.value.createdAt)
  }

  diff(): IConnectQuotationDiffer {
    const diff: IConnectQuotationDiffer = {
      workContent: !this.value.workContent,
      contractPeriod: !this.value.contractPeriod,
    }

    let all = false
    for (const diffKey in diff) {
      // @ts-ignore
      if (diff[diffKey]) {
        all = true
      }
    }

    diff.all = all

    return diff
  }

  public get connectCompanyTel(): string | undefined {
    return this.connectCompanyTelTemporary
  }

  public set connectCompanyTel(value: string | undefined) {
    if (!value) {
      return
    }

    // @MEMO: 一度リセットしないとgetterが発火しない
    this.connectCompanyTelTemporary = value
    const replaced = this.removeUnnecessaryTelStr(value)

    this.connectCompanyTelTemporary = replaced

    this.value.connectCompanyTel = replaced
  }

  public setConnectClientAddress(value: string) {
    this.value.connectClientAddress = value
  }

  private removeUnnecessaryTelStr(str: string) {
    return str
      .replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248)
      })
      .replace(/[‐－―ー]/g, '-')
      .replace(/[^\d-]/g, '')
  }

  getConnectContractRenewalHashId(): string | null | undefined {
    return this.value.connectContractRenewalHashId
  }

  /**
   * セゾン対応のバージョンかどうか
   * 新規の契約は基本的にセゾン対応している
   */
  public get enableSaisonVersion(): boolean {
    return (
      (this.value?.contractVersion ?? ConnectQuotation.VERSION2) >=
      ConnectQuotation.VERSION2
    )
  }

  public get enableAchievementReward(): boolean {
    return (
      this.value.achievementRewardDefine !== null &&
      this.value.achievementRewardDefine !== undefined &&
      this.value.achievementRewardDetermination !== null &&
      this.value.achievementRewardDetermination !== undefined
    )
  }

  private calcFixedRewardTotal(fixedReward: IFixedReward): number {
    if (!fixedReward.fixedRewardCount || !fixedReward.fixedRewardPrice) {
      return 0
    }

    let fixedRewardCount = 0
    if (typeof fixedReward.fixedRewardCount === 'string') {
      fixedRewardCount = Number(fixedReward.fixedRewardCount.replace(/,/g, ''))
    } else {
      fixedRewardCount = fixedReward.fixedRewardCount
    }

    let fixedRewardPrice = 0
    if (typeof fixedReward.fixedRewardPrice === 'string') {
      fixedRewardPrice = Number(fixedReward.fixedRewardPrice.replace(/,/g, ''))
    } else {
      fixedRewardPrice = Number(fixedReward.fixedRewardPrice)
    }

    return fixedRewardCount * fixedRewardPrice
  }

  public setFixedRewards(fixedRewards: IFixedReward[]): void {
    this.value = {
      ...this.value,
      fixedRewards,
    }
  }

  public addFixedReward(): void {
    const fixedReward = {
      hash: ConnectQuotation.generateHash(),
    } as IFixedReward

    const fixedRewards: IFixedReward[] =
      [...(this.value.fixedRewards as IFixedReward[])] ?? []
    fixedRewards.push(fixedReward)

    this.value = {
      ...this.value,
      fixedRewards,
    }
  }

  public get subtotal(): number {
    if (!this.value.fixedRewards) {
      return 0
    }

    return this.value.fixedRewards.reduce((currentValue, fixedReward) => {
      return currentValue + this.calcFixedRewardTotal(fixedReward)
    }, 0)
  }

  public get tax(): number {
    if (!this.value.fixedRewards) {
      return 0
    }

    const value = this.value.fixedRewards.reduce(
      (currentValue, fixedReward) => {
        const taxRate = fixedReward.fixedRewardTax
          ? Number.parseFloat(fixedReward.fixedRewardTax)
          : ConnectQuotation.CONSUMPTION_TAX_RATE

        return currentValue + this.calcFixedRewardTotal(fixedReward) * taxRate
      },
      0
    )

    return Math.floor(value)
  }

  public get total(): number {
    return this.subtotal + this.tax
  }

  public get fixedRewardsByPaymentDate(): IGroupedFixedReward[] {
    if (!this.value.fixedRewards || !this.value.fixedRewards.length) {
      return []
    }

    const result = [] as IGroupedFixedReward[]
    let currentRow: IGroupedFixedReward | null = null

    this.value.fixedRewards.forEach((fixedReward) => {
      if (
        currentRow === null ||
        fixedReward.fixedRewardPaymentDate !== currentRow.fixedRewardPaymentDate
      ) {
        currentRow = {
          fixedRewardPaymentDate: fixedReward.fixedRewardPaymentDate as string,
          items: [fixedReward],
          rowspan: 1,
        }
        result.push(currentRow)
      } else {
        currentRow.items.push(fixedReward)
        currentRow.rowspan++
      }
    })

    return result.sort((a, b) => {
      if (a.fixedRewardPaymentDate > b.fixedRewardPaymentDate) {
        return 1
      }
      if (a.fixedRewardPaymentDate < b.fixedRewardPaymentDate) {
        return -1
      }
      return 0
    })
  }

  get enableAddReward(): boolean {
    if (!this.value.fixedRewards) {
      return true
    }

    return this.value.fixedRewards?.length < 20
  }

  get payload(): IConnectQuotation {
    return {
      ...this.value,
      fixedRewards: this.value.fixedRewards?.map((fixedReward) => {
        return {
          ...fixedReward,
          fixedRewardCount: fixedReward.fixedRewardCount
            ? fixedReward.fixedRewardCount.toString().replace(/,/g, '')
            : '',
          fixedRewardPrice: fixedReward.fixedRewardPrice
            ? fixedReward.fixedRewardPrice.toString().replace(/,/g, '')
            : '',
          fixedRewardTotal: fixedReward.fixedRewardTotal
            ? fixedReward.fixedRewardTotal.toString().replace(/,/g, '')
            : '',
        }
      }),
    }
  }
}
