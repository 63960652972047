import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export interface ILock {
  name: string
  is_locked: boolean
}

@Module({ stateFactory: true, namespaced: true, name: 'Lock' })
export default class Lock extends VuexModule {
  locks: ILock[] = []

  public types: { [index: string]: string } = {
    propose: 'propose',
    login: 'login',
    message: 'message',
    company: 'company',
    quotation: 'quotation',
    hearing_sheet: 'hearing_sheet',
    contract: 'contract',
    reference_check: 'reference_check',
    support_achievement: 'support_achievement',
    support_achievement_review: 'support_achievement_review',
    log: 'log',
  }

  @Mutation
  lock(name: string) {
    const index = this.locks.findIndex((lock) => lock.name === name)
    if (index < 0) {
      this.locks.push({ name, is_locked: true })

      return
    }

    this.locks[index].is_locked = true
  }

  @Mutation
  unlock(name: string) {
    const index = this.locks.findIndex((lock) => lock.name === name)
    if (index === undefined) {
      return
    }
    if (this.locks[index] === undefined) {
      return
    }

    this.locks[index].is_locked = false
  }

  get shouldLock() {
    const self = this

    return (name: string) => {
      const lock = self.locks.find((lock) => lock.name === name)

      return lock?.is_locked ?? false
    }
  }
}
