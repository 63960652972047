















































































import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import WrapperIcon from '~/components/WrapperIcon.vue'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'
import { ClientEmailBot } from '~/components'
import HeaderClient from '~/components/header/header-client.vue'
import HeaderPartner from '~/components/header/header-partner.vue'
import ConnectFooter from '~/components/ConnectFooter.vue'
import ConnectV2Snackbar from '~/components/common/components/v2/ConnectV2Snackbar.vue'

@Component({
  components: {
    ConnectV2Snackbar,
    HeaderPartner,
    HeaderClient,
    ClientEmailBot,
    ConnectIcon,
    WrapperIcon,
    ConnectFooter,
  },
})
export default class Wrapper extends Vue {
  @Prop({ default: false })
  private is_client!: boolean

  @Prop({ default: false })
  private outside!: boolean

  @Prop({ default: false })
  private is_header_fixed!: boolean

  @Prop({ default: false })
  private should_header_hide!: boolean

  @Prop({ default: false })
  private should_title_show!: boolean

  @Prop({ default: true })
  private should_slash_show!: boolean

  @Prop({ default: null })
  private backgroundColor!: string | null

  @Prop({ default: false })
  private should_title_wrapper_show!: boolean

  @Prop({ default: false })
  private is_preview!: boolean

  @Prop({ default: false })
  private isProfile!: boolean

  @Prop({ default: false })
  private isProductTopPage!: boolean

  @Prop({ default: false })
  private isWithoutScroll!: boolean

  private show_header: boolean = false
  private show_header_menu: boolean = false
  private show_help_menu: boolean = false

  private onWheel(event: Event) {
    if (this.isWithoutScroll) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  get partnerProfileUrl() {
    return '/company/profile/connect_company'
  }

  private headerAppear(event: Event, element: HTMLDivElement) {
    if (!this.is_header_fixed) {
      this.show_header = this.isScrollTop()
    }
  }

  private isCurrentPath(path: string): boolean {
    return this.$route.path === path
  }

  private contains(paths: string[]): boolean {
    return (
      paths.filter((path: string) => {
        return this.$route.path.includes(path)
      }).length > 0
    )
  }

  mounted() {
    this.show_header = this.is_header_fixed
  }

  updated() {
    if (this.is_header_fixed) {
      this.show_header = true
    } else {
      this.show_header = this.isScrollTop()
    }
  }

  isScrollTop(): boolean {
    return window.scrollY > 0
  }

  private toggleHeaderMenu() {
    this.show_header_menu = !this.show_header_menu
  }

  private toggleHelpMenu() {
    this.show_help_menu = !this.show_help_menu
  }

  private logout() {
    this.$services.auth.logout()
    this.$services.log.clearAccessLogInterval()
  }

  get isCreatedMessageRoom() {
    return this.$auth.user?.is_created_message_room ?? false
  }

  get isTerm(): boolean {
    return this.$route.path.startsWith('/terms/')
  }

  get isCompany(): boolean {
    return !this.is_client && !this.isTerm
  }

  get isSupportAccount(): boolean {
    return (
      this.$services.user.isSupportClient ||
      this.$services.user.isSupportPartner
    )
  }

  get style(): string {
    let currentStyle = ''
    if (this.backgroundColor) {
      currentStyle = currentStyle + `background: ${this.backgroundColor};`
    }
    return currentStyle
  }
}
