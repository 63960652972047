

























import { Component, Vue } from 'nuxt-property-decorator'
import Modal from '../Modal.vue'
import { ModalStore } from '~/utils/store-accessor'
import Paging from '~/components/common/connect/paging.vue'
@Component({
  components: { Paging, Modal },
})
export default class ClientUrgeProfileModal extends Vue {
  private closeModal() {
    this.$auth.fetchUser()
    ModalStore.hide('ClientUrgeProfileModal')
  }

  get shouldDisplay(): boolean {
    return ModalStore.shouldDisplay('ClientUrgeProfileModal')
  }
}
