

































import { Component, Emit, Prop, PropSync, Vue } from 'nuxt-property-decorator'

type SelectOption = {
  label: string
  value: string | number | boolean
}

@Component
export default class ConnectDropdown extends Vue {
  @Prop()
  private id!: string

  @PropSync('modelValue')
  private model!: string | number | boolean | null

  @Prop()
  private disabled!: boolean

  @Prop()
  private placeholder!: string

  @Prop()
  private hint!: string

  @Prop()
  private error!: boolean

  @Prop()
  private options!: SelectOption[]

  get componentId(): string {
    return this.id || Math.random().toString(32).substring(2)
  }

  get errorId(): string {
    return `${this.componentId}-error`
  }

  @Emit('change')
  private onChange() {}
}
