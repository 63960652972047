var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.s.contents},[_c('div',{class:_vm.s.left},[_c('ClientProductStatusIcon',{attrs:{"connect-proposal-status":_vm.proposal.connectProposalStatus}}),_vm._v(" "),_c('client-product-id-icon-wrapper',{attrs:{"proposal":_vm.proposal,"size":"large"}}),_vm._v(" "),_c('div',{class:_vm.s.teamHashId},[_vm._v("\n      ID: "),_c('span',[_vm._v(_vm._s(_vm.proposal.company.companyHashIdShort))])])],1),_vm._v(" "),_c('div',{class:_vm.s.right},[_c('div',{class:_vm.s.favoriteButtonWrapper},[_c('ProposalFavoriteButton',{attrs:{"proposal":_vm.proposal}})],1),_vm._v(" "),(_vm.proposal.connectProposalTitle)?_c('highlight-text',{class:_vm.s.connectProposalTitle,attrs:{"origin-text":_vm.proposal.connectProposalTitle,"search-words":_vm.searchWords}}):_vm._e(),_vm._v(" "),_c('highlight-text',{class:_vm.s.connectProposalContent,attrs:{"origin-text":_vm.proposal.connectProposalContent,"search-words":_vm.searchWords}}),_vm._v(" "),_c('div',{class:_vm.s.team},[_c('NuxtLink',{class:( _obj = {}, _obj[_vm.s.highlight] = _vm.hasCompanyHighLight, _obj ),attrs:{"to":'/client/profile/connect_company/' + _vm.proposal.connectProposalHashId}},[_c('span',[_vm._v("提案したチームの詳細を見る")]),_vm._v(" "),_c('div',{class:_vm.s.fa},[_c('font-awesome-icon',{attrs:{"icon":"arrow-right"}})],1)])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal.connectProposalStatus === 'waiting'),expression:"proposal.connectProposalStatus === 'waiting'"}],class:[_vm.s.buttons, _vm.s.buttons_flex]},[_c('div',{class:[
          _vm.s.submit,
          _vm.s.waiting,
          ( _obj$1 = {}, _obj$1[_vm.s.disabled] = !_vm.canNegotiate && !_vm.is_from_detail, _obj$1 ),
          ( _obj$2 = {}, _obj$2[_vm.s.locked] = _vm.isLocked, _obj$2 ) ],on:{"click":_vm.negotiate}},[_c('font-awesome-icon',{attrs:{"icon":"comments"}}),_c('span',[_vm._v("商談する")])],1),_vm._v(" "),_c('div',{class:_vm.s.cancel,on:{"click":_vm.reject}},[_vm._v("この提案を見送る")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal.connectProposalStatus === 'negotiating'),expression:"proposal.connectProposalStatus === 'negotiating'"}],class:[_vm.s.buttons, _vm.s.buttons_flex]},[(_vm.isRequestedQuotationApprove)?_c('div',{class:[_vm.s.submit],on:{"click":_vm.quotation}},[_c('span',[_vm._v("発注書を確認する")])]):(_vm.isRequestedQuotationEdit)?_c('div',{class:[_vm.s.submit, _vm.s.disable]},[_c('span',[_vm._v("修正依頼中")])]):(_vm.isRequestedQuotationConfirm)?_c('div',{class:[_vm.s.submit],on:{"click":_vm.quotation}},[_c('span',[_vm._v("見積を確認する")])]):(
          _vm.isRequestedQuotation ||
          (_vm.updatedProposal && _vm.updatedProposal.isRequestedQuotation)
        )?_c('div',{class:[_vm.s.submit, _vm.s.disable]},[_c('span',[_vm._v("見積依頼中")])]):_c('div',{class:[_vm.s.submit, ( _obj$3 = {}, _obj$3[_vm.s.locked] = _vm.isLocked, _obj$3 )],on:{"click":_vm.requestQuotation}},[_c('span',[_vm._v("見積を依頼する")])]),_vm._v(" "),_c('div',{class:_vm.s.cancel,on:{"click":_vm.reject}},[_vm._v("この提案を見送る")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.proposal.connectProposalStatus === 'accepted' ||
        _vm.proposal.connectProposalStatus === 'terminated'
      ),expression:"\n        proposal.connectProposalStatus === 'accepted' ||\n        proposal.connectProposalStatus === 'terminated'\n      "}],class:[_vm.s.buttons, _vm.s.buttons_end]},[_c('div',{class:[_vm.s.cancel, _vm.s.accepted]}),_vm._v(" "),_c('div',{class:[_vm.s.submit, _vm.s.accepted],on:{"click":function($event){$event.stopPropagation();return _vm.openThread(_vm.proposal)}}},[_c('font-awesome-icon',{attrs:{"icon":"comments"}}),_c('span',[_vm._v("メッセージ")])],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.proposal.connectProposalStatus === 'rejected' ||
        _vm.proposal.connectProposalStatus === 'rejected_not_correspond'
      ),expression:"\n        proposal.connectProposalStatus === 'rejected' ||\n        proposal.connectProposalStatus === 'rejected_not_correspond'\n      "}],class:_vm.s.buttons},[_vm._v("\n      この提案はお見送りになりました\n    ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canNegotiate && !_vm.is_from_detail),expression:"!canNegotiate && !is_from_detail"}],class:_vm.s.urge_profile},[_vm._v("\n      パートナーと商談するには"),_c('nuxt-link',{attrs:{"to":"/client/profile/connect_client"}},[_vm._v("プロフィールよりご住所の登録")]),_vm._v("が必要です。\n    ")],1),_vm._v(" "),_c('ClientProposalRejectModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_opened_reject_modal),expression:"is_opened_reject_modal"}],on:{"closeModal":function($event){_vm.is_opened_reject_modal = false},"reject":function($event){return _vm.reject($event, true)}}})],1),_vm._v(" "),_c('client-urge-profile-modal'),_vm._v(" "),_c('client-confirm-modal',{attrs:{"proposal":_vm.proposal},on:{"onNegotiate":_vm.executeNegotiate,"onRequestQuotation":_vm.executeRequestQuotation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }