// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button_3Nlb9{font-style:normal;font-weight:600;font-size:14px;line-height:21px;display:flex;justify-content:center;align-items:center;text-align:center;width:200px;height:40px;border-radius:20px;cursor:pointer;border:none}.button_3Nlb9.primary_2_WHw{background:linear-gradient(90deg,#0066cb,#00a1c7 50.13%,#00a5a7);color:#fff}.button_3Nlb9.primary_ver2_3g8vO{background:#3379b7;color:#fff}.button_3Nlb9.primary_ver2_3g8vO:hover{background:#2c69a0;opacity:1}.button_3Nlb9.primary_ver2_3g8vO:active{background:#245480}.button_3Nlb9.secondary_2xbX4{background:#2f5980;color:#fff}.button_3Nlb9.tertiary_2Ih5U{background:#fff;color:#2f5980;border:1px solid #2f5980}.button_3Nlb9.gray_1VrgW{background:#e9edf1;color:#2f5980}.button_3Nlb9:hover{opacity:.8}.button_3Nlb9.clientPrimary_RxluJ{background:#3379b7;color:#fff}.button_3Nlb9.clientPrimary_RxluJ:hover{background:#2c69a0;opacity:1!important}.button_3Nlb9.clientPrimary_RxluJ:disabled{background:#245480;color:#afadac}.button_3Nlb9.transparent_34_ct{background:transparent;color:#555}.button_3Nlb9.sm_kn5jI{width:160px;height:32px;font-size:12px;line-height:18px}.button_3Nlb9:disabled{background:#e9edf1;color:#c0cbd8;cursor:not-allowed}.inner_1bJrX{display:flex;justify-content:center;align-items:center;width:100%}.icon_19Rlw{font-size:18px}.icon_19Rlw.primary_2_WHw,.icon_19Rlw.secondary_2xbX4{color:#fff}.icon_19Rlw.gray_1VrgW,.icon_19Rlw.tertiary_2Ih5U{color:#2f5980}", ""]);
// Exports
exports.locals = {
	"button": "button_3Nlb9",
	"primary": "primary_2_WHw",
	"primary_ver2": "primary_ver2_3g8vO",
	"secondary": "secondary_2xbX4",
	"tertiary": "tertiary_2Ih5U",
	"gray": "gray_1VrgW",
	"clientPrimary": "clientPrimary_RxluJ",
	"transparent": "transparent_34_ct",
	"sm": "sm_kn5jI",
	"inner": "inner_1bJrX",
	"icon": "icon_19Rlw"
};
module.exports = exports;
