




































































































import { Component, Emit, Vue } from 'nuxt-property-decorator'
import {
  ModalStore,
  SnackbarStore,
  TermStore,
  TopicStore,
} from '~/utils/store-accessor'
import FormModal from '~/components/common/modal/form-modal.vue'
import { IExcerpt, ITopic } from '~/Interfaces'

@Component({
  components: {
    FormModal,
  },
})
export default class ConnectCancellationModal extends Vue {
  private isDateInputted: boolean = false
  private isSubmitting: boolean = false

  private excerpt!: IExcerpt | null

  get shouldDisplay(): boolean {
    return ModalStore.shouldDisplay('ConnectCancellationModal')
  }

  get topic(): ITopic | undefined {
    return TopicStore.getTopic
  }

  private close() {
    if (this.isSubmitting) {
      return
    }

    this.closeModal()
  }

  get excerptTitle(): string {
    return this.excerpt?.title ?? 'ダミータイトル'
  }

  get excerptText(): string {
    return this.excerpt?.text ?? '抜粋文が入ります。この文章はダミーです。'
  }

  get isConnectCompanySelf(): boolean {
    return TopicStore.isConnectCompanySelf
  }

  private closeModal() {
    ModalStore.hide('ConnectCancellationModal')
  }

  private cancel() {
    this.close()
  }

  private date() {
    this.isDateInputted = true
  }

  private changeTextarea() {
    const textarea = this.$refs.reason as HTMLTextAreaElement
    const remainLength = textarea.maxLength - textarea.value.length

    const remain = this.$refs.remain as HTMLSpanElement
    remain.textContent = remainLength.toString()
  }

  private async submit(e: Event) {
    if (this.topic === undefined) {
      return
    }

    if (this.isSubmitting) return

    const form = this.$refs.form as HTMLFormElement

    if (form.checkValidity()) {
      e.preventDefault()

      const threadHashId = this.topic.thread.threadHashId

      const date = this.$refs.date as HTMLInputElement
      const reason = this.$refs.reason as HTMLTextAreaElement

      this.isSubmitting = true
      await this.$services.contract.cancellation(
        threadHashId,
        date.value,
        reason.value
      )
      this.isSubmitting = false

      await this.onSubmit()
      SnackbarStore.show('cancellation')
      this.close()
    }
  }

  async mounted() {
    await this.$services.term.getTerms()
    this.excerpt = TermStore.getExcerptByType(
      TermStore.pageTypes.connect_terms,
      TermStore.excerptTypes.contract_termination
    )
  }

  @Emit('onSubmit')
  private onSubmit() {}
}
