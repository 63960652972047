


















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ICaseInterviewOgp } from '~/Interfaces/connectCompany/IConnectCompany'

@Component({})
export default class PartnerProfileCaseInterviewCard extends Vue {
  @Prop()
  private ogp!: ICaseInterviewOgp

  get domainName(): string {
    return new URL(this.ogp.url).hostname
  }
}
