import { render, staticRenderFns } from "./Wrapper.vue?vue&type=template&id=7df91ab9&"
import script from "./Wrapper.vue?vue&type=script&lang=ts&"
export * from "./Wrapper.vue?vue&type=script&lang=ts&"
import style0 from "./Wrapper.vue?vue&type=style&index=0&lang=scss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderClient: require('/var/www/kakutoku_connect/nuxt/src/components/header/header-client.vue').default,HeaderPartner: require('/var/www/kakutoku_connect/nuxt/src/components/header/header-partner.vue').default,ConnectFooter: require('/var/www/kakutoku_connect/nuxt/src/components/ConnectFooter.vue').default,ConnectV2Snackbar: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/v2/ConnectV2Snackbar.vue').default})
